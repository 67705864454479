import {
  getLoginServerSideProps,
  LoginPage,
  LoginPageProps,
} from '@pig-frontend/pig-common'
import { withMiddleware } from '@pig-casino/middlewares/get-serverside-props'
import { ISystemStatusResponse } from '@pig-common/types/SystemStatus.type'
import Head from 'next/head'

interface ILoginProps extends LoginPageProps {
  systemStatus: ISystemStatusResponse
}

export default function Login({ systemStatus, ...props }: ILoginProps) {
  return (
    <>
      <Head>
        <title>เข้าสู่ระบบ / Log in </title>
        <meta name="title" content=" เข้าสู่ระบบ / Log in " />
        <meta property="og:title" content=" เข้าสู่ระบบ / Log in " />
        <meta name="twitter:title" content=" เข้าสู่ระบบ / Log in " />

        <meta
          name="description"
          content="PIGBET คาสิโนออนไลน์ที่ดีที่สุด เว็บพนันครบวงจร ที่พร้อมบริการจากทุกค่ายเกมดังยอดนิยมให้คุณได้ทดลองเล่นแล้วที่นี้ พร้อมโปรโมชันแจกเครดิตฟรีเพียงสมัครสมาชิก หาเงินได้ง่ายๆ คาสิโนสดเล่นง่าย แตกจริง จ่ายเงินไว"
        />
        <meta
          property="og:description"
          content="PIGBET คาสิโนออนไลน์ที่ดีที่สุด เว็บพนันครบวงจร ที่พร้อมบริการจากทุกค่ายเกมดังยอดนิยมให้คุณได้ทดลองเล่นแล้วที่นี้ พร้อมโปรโมชันแจกเครดิตฟรีเพียงสมัครสมาชิก หาเงินได้ง่ายๆ คาสิโนสดเล่นง่าย แตกจริง จ่ายเงินไว"
        />
        <meta
          name="twitter:description"
          content="PIGBET คาสิโนออนไลน์ที่ดีที่สุด เว็บพนันครบวงจร ที่พร้อมบริการจากทุกค่ายเกมดังยอดนิยมให้คุณได้ทดลองเล่นแล้วที่นี้ พร้อมโปรโมชันแจกเครดิตฟรีเพียงสมัครสมาชิก หาเงินได้ง่ายๆ คาสิโนสดเล่นง่าย แตกจริง จ่ายเงินไว"
        />
      </Head>

      <LoginPage {...props} />
    </>
  )
}

export const getServerSideProps = withMiddleware(async ({ ...ctx }) => {
  return {
    props: {
      ...(await getLoginServerSideProps(ctx)).props,
    },
  }
})
